export default class CallController{
    constructor(schema, data){
        this.setData(schema, data)
    }

    setData(schema, data){
        this.setStatusLoading(false)
        this.setPriorityLoading(false)
        this.setChamado(data.chamado.registros[0])
        this.setChamadoOcorrencias(data.chamado_ocorrencia.registros)
        this.setParticipants(data.chamado_usuario.registros)
        this.setParticipantAssigned(data.chamado_usuario.registros.find(cu => cu.usuario_responsavel) ?? null)
        this.setComments(data.chamado_ocorrencia.registros.filter(co => [9, 10].includes(co.tipo)))
        this.setSystemComments(data.chamado_ocorrencia.registros.filter(co => ![9, 10].includes(co.tipo)))
        this.setStatusOptions(schema.sis_tabela
            .sis_campo_tabela.find(sct => sct.id_campo_tabela == '3ed478b4-51ac-48f5-ab43-f12624d1c32f')
            .sis_lista_edicao.sis_opcao_lista_valor.sort((a, b) => a.id - b.id)
        )
        this.setPriorityOptions(schema.sis_tabela
            .sis_campo_tabela.find(sct => sct.id_campo_tabela == 'c9a1ea11-49cb-4a72-bbe8-59f1b594f7f8')
            .sis_lista_edicao.sis_opcao_lista_valor.sort((a, b) => a.id - b.id)
        )
    }
    setChamado(chamado){
        this.tipo = chamado.tipo
        this.titulo = chamado.titulo
        this.situacao = chamado.situacao
        this.descricao = chamado.descricao
        this.id_chamado = chamado.id_chamado
        this.prioridade = chamado.prioridade
        this.titulo_tipo = chamado.titulo_tipo
        this.data_inclusao = chamado.data_inclusao
        this.data_vencimento = chamado.data_vencimento
        this.id_departamento = chamado.id_departamento
        this.titulo_situacao = chamado.titulo_situacao
        this.usuario_inclusao = chamado.usuario_inclusao
        this.nome_departamento = chamado.nome_departamento
        this.titulo_prioridade = chamado.titulo_prioridade
        this.id_usuario_inclusao = chamado.id_usuario_inclusao
        this.usuario_responsavel = chamado.usuario_responsavel
        this.data_ultima_atualizacao = chamado.data_ultima_atualizacao
        this.id_acordo_nivel_servico = chamado.id_acordo_nivel_servico
    }
    setChamadoOcorrencias(chamado_ocorrencias){
        this.chamado_ocorrencias = chamado_ocorrencias
    }
    setParticipants(participants){
        this.participants = participants.sort(cu => cu.usuario_responsavel ? -1 : 1).map(usuario => {
            return {
                ...usuario,
                loading: false,
            }
        })
    }
    setParticipantAssigned(participant_assigned){
        this.participant_assigned = participant_assigned
    }
    setComments(comments){
        this.comments = comments
    }
    setSystemComments(system_comments){
        this.system_comments = system_comments
    }
    setStatusOptions(statusOptions){
        this.statusOptions = statusOptions
    }
    setPriorityOptions(priorityOptions){
        this.priorityOptions = priorityOptions
    }
    getMentionedComment(id_chamado_ocorrencia_mencionado){
        return this.chamado_ocorrencias.find(co => co.id_chamado_ocorrencia == id_chamado_ocorrencia_mencionado)
    }
    setStatusLoading(bool){
        console.log('bool', bool)
        this.statusOptionsLoading = bool
    }
    setPriorityLoading(bool){
        this.priorityOptionsLoading = bool
    }
    removeParticipant(id_usuario){
        this.participants = this.participants.filter(p => p.id_usuario != id_usuario)
    }
}